import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

TokenLogo.propTypes = {
  symbol: PropTypes.string,
  header: PropTypes.bool,
  size: PropTypes.string,
}

export default function TokenLogo({ symbol, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [symbol])

  if (error || BAD_IMAGES[symbol]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }

  let path = `http://list.linqto-dev.com/images/${symbol}.png`
  if (symbol === undefined) path = `https://cdn-icons-png.flaticon.com/512/71/71768.png`

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[symbol] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
