import React, { useMemo } from 'react'
import styled from 'styled-components'
import Panel from '../Panel'
import { AutoColumn } from '../Column'
import { RowFixed } from '../Row'
import { TYPE } from '../../Theme'
import { usePairData } from '../../contexts/PairData'
import { formattedNum } from '../../utils'

const PriceCard = styled(Panel)`
  position: absolute;
  right: -220px;
  width: 220px;
  top: -20px;
  z-index: 9999;
  height: fit-content;
  background-color: ${({ theme }) => theme.bg1};
`

function formatPercent(rawPercent) {
  if (rawPercent < 0.01) {
    return '<1%'
  } else return parseFloat(rawPercent * 100).toFixed(0) + '%'
}

export default function UniPrice() {
  const usdPair = usePairData('0x27eaf07651d43774fcaf155867b0f10b462b5dca')

  const totalLiquidity = useMemo(() => {
    return usdPair ? usdPair.trackedReserveUSD : 0
  }, [usdPair])

  const usdPerXrp = usdPair ? parseFloat(usdPair.token0Price).toFixed(2) : '-'

  return (
    <PriceCard>
      <AutoColumn gap="10px">
        <RowFixed>
          <TYPE.main>USD/XRP: {formattedNum(usdPerXrp, true)}</TYPE.main>
          <TYPE.light style={{ marginLeft: '10px' }}>
            {usdPair && totalLiquidity ? formatPercent(usdPair.trackedReserveUSD / totalLiquidity) : '-'}
          </TYPE.light>
        </RowFixed>
      </AutoColumn>
    </PriceCard>
  )
}
